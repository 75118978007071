import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const Log = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  padding: 3.2rem;

  h3.ant-typography {
    font-size: 2rem;
    margin-bottom: 2.8rem;
  }

  .ant-tag {
    line-height: 1;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.3rem 0.8rem;
  }

  ${media.lg`
    padding: 2.4rem;
  `}
`

Log.Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

Log.Action = styled.a`
  min-width: 3.2rem;
  height: 3.2rem;
  background: url(${({ showResume }) => (showResume ? images.iconWebinarPlay : images.iconWebinarNew)}) no-repeat
    center / 2rem;
  border: 0.1rem solid ${({ showResume, theme }) => (showResume ? theme.colors.red[200] : theme.colors.green[200])};
  border-radius: 50%;
  display: block;
  cursor: ${({ showResume }) => (showResume ? 'pointer' : 'default')};

  &:hover {
    background-color: ${({ showResume }) => (showResume ? 'rgba(238, 129, 117, 0.16)' : null)};
  }
`

export { Log }
